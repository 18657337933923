.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100vh;
}

.sticky-top {
div {
  position: sticky;
  top: 0;
  height: 100vh;
}
}

.header{
  background: url("../public/img/PandaWebSiteBG.jpg") no-repeat top center fixed;
  background-size: cover;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  text-align: left;
  font-size: 50px;
}
.logo{
  padding-left: 10px;
  padding-top: 10px;
}

.about {
  align-items: center;
  padding-bottom: 30px;
}

.mankind {
  align-items: center;
  padding-bottom: 30px;
}

.hpgTitle {
  align-items: center;
  text-align: center;
  padding: 10px;
}

.hpgText {
  align-items: center;
  text-align: justify;
  padding: 10px 00px 10px 00px;
}

.hpgVideoContainer {
  align-items: center;
  /*height: calc((80vw/16)*9);*/
  /*width: 80vw;*/
  /*margin-left: 0px;*/
  /*translateX(-50%);*/
  /*left: 50%*/
  /*margin-right: -100px;*/
}

.hpgVideoFrame {
  align-items: center;
  /*height: calc((80vw/16)*9);*/
  /*width: 80vw;*/
  /*margin-left: 0px;*/
  /*translateX(-50%);*/
  /*left: 50%*/
  /*margin-right: -100px;*/
}

.px10TopMargin {
  margin-top: 50px;

}

/*@media only screen and (max-width: 980px) {*/
/*  .hpgTitle {*/
/*    align-items: center;*/
/*    text-align: center;*/
/*    padding: 10px;*/
/*  }*/

/*  .hpgText {*/
/*    align-items: center;*/
/*    text-align: justify;*/
/*    padding: 10px 100px 10px 100px;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 981px) {*/
/*  .hpgTitle {*/
/*    align-items: center;*/
/*    text-align: center;*/
/*    padding: 10px;*/
/*  }*/

/*  .hpgText {*/
/*    align-items: center;*/
/*    text-align: justify;*/
/*    padding: 10px 25% 10px 25%;*/
/*  }*/
/*}*/


